import * as React from 'react'
import Header from "./Header";
import Footer from "./Footer";
import MenuOnDesktop from "./MenuOnDesktop";

interface Props {
    children: any
}

const Content = ({children}: Props) => {

    return(
        <div id={'body'}>

            <Header/>

            <MenuOnDesktop/>

            <div className={'opakowanie'}>
                <div className="content">
                    <div className={"page"}>
                        {children}
                    </div>
                </div>
                <Footer/>
            </div>

        </div>
    )
}

export default Content;
