import React, {Children, useCallback, useEffect, useState} from 'react';
import Content from "../../globalComponent/Content";
// import Box from "../globalComponent/Box";
import {Calendar, momentLocalizer} from 'react-big-calendar';
import {FormControl} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import '../../styles/mobilnosc.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "moment/locale/pl";
// import {FormControl} from "react-bootstrap";
// import PopupWrapper from "../globalComponent/PopupWrapper";
// import DodajDyspozycyjnosc from "./DodajDyspozycyjnosc";
import {
    dodajDyspozycyjnoscWG,
    getKalendarzWG,
    getNumer,
    scrollToTop
} from "../../utilities/Serwis";
import {Modal, message, Alert, Row, TimePicker, Col} from "antd";
import {UserOutlined} from "@ant-design/icons";
// import "antd/dist/antd.css";
// import DodajDyspozycyjnoscV2 from "./DodajDyspozycyjnoscV2";
// import {Children} from "react";

interface Props {
    // match: any
}

const MobilnoscMain = () => {

    const [dyspozycje, setDyspozycje] = useState([]);
    const [dyspozycja, setDyspozycja]: any = useState();
    const [dyspozycjaId, setDyspozycjaId]: any = useState();
    const [scrollDate, setScrollDate] = useState(moment().format("YYYY-MM-DD"));
    const [allowToSelect, setAllowToSelect] = useState(true);
    const [czasStart, setCzasStart]: any = useState('06:00');
    const [czasKoniec, setCzasKoniec] = useState('18:00');
    const [loader, setLoader] = useState(false);
    const [showFormularz, setShowFormularz] = useState(false);
    const [showModalUsun, setShowModalUsun] = useState(false);
    const [view, setView]: any = useState('month');
    const stepValue = 30;
    const timeslotsValue = 2;
    const today = new Date(Date.now());

    useEffect(() => {
        scrollToTop();
        pobierzEventy();
    }, [])

    const pobierzEventy = () => {
        getKalendarzWG().then((response) => {
            // this.setState({events: this.przeksztalcResponseDoKalendarza(response.data)});
            setDyspozycje(przeksztalcResponseDoKalendarza(response.data));
        }).catch((e) => {
            console.log('error')
        })
    }

    const formatTitle = (item: any) => {
        // console.log(item)
        return `${item.IMIE} ${item.NAZWISKO}`
        // return `${item.IMIE} ${item.NAZWISKO} (${moment(item.dataStart).format('HH:mm')} - ${moment(item.dataKoniec).format('HH:mm')})`
    }

    const przeksztalcResponseDoKalendarza = (response: any): any => {
        // kopiowanie tablicy ze zmienionymi polami by pasowały do kalendarza
        let arr = Array.from(response, (item: any) => {

            return {
                id: item.ID,
                numer: item.numer,
                title: formatTitle(item),
                imie: item.IMIE,
                nazwisko: item.NAZWISKO,
                desc: 'description',
                start: new Date(item.dataStart),
                end: new Date(item.dataKoniec),
                // start: item.dataStart,
                // end: item.dataKoniec,
                // start: moment(item.dataStart),
                // end: moment(item.dataKoniec),
                // allDay: true,
                c: item.PRAW_C,
                // dzien: item.dzien,
                // noc: item.noc
            }
        });

        console.log(arr)

        return arr
    }

    const changeNavigate = (e: any) => {
        // this.setState({scrollDate: moment(e).format("YYYY-MM-DD")});
        setScrollDate(moment(e).format("YYYY-MM-DD"))
    }

    const handleSelectEvent = (event: any) => {
        // scrollToTop();
        // this.setState({scrollDate: moment(event.start).format("YYYY-MM-DD")});
        setScrollDate(moment(event.start).format("YYYY-MM-DD"))
        onChangeView('day')
    }

    const czySkladNaDzien = (data: any) => {
        let eve = dyspozycje;
        let zielony = false;

        if(moment(data).isAfter(moment('2023-09-01')) && moment(data).isBefore(moment('2024-09-01'))){
            let eventsPerDeyDzien = eve.filter((e: any) => (moment(e.start).format("YYYY-MM-DD") === moment(data).format("YYYY-MM-DD")) && e.dzien === '1');
            let eventsPerDeyNoc = eve.filter((e: any) => (moment(e.start).format("YYYY-MM-DD") === moment(data).format("YYYY-MM-DD")) && e.noc === '1');

            // console.log(moment(data).format("YYYY-MM-DD"), eventsPerDeyDzien.length, eventsPerDeyNoc.length)

            zielony = ((eventsPerDeyDzien.length >= 6) && (eventsPerDeyNoc.length >= 6));
        } else {
            let eventsPerDey = eve.filter((e: any) => moment(e.start).format("YYYY-MM-DD") === moment(data).format("YYYY-MM-DD"));

            zielony = (eventsPerDey.length === 4)
        }


        return zielony
    }

    const czySkladNaDzienV2 = (data: any) => {
        let eve = dyspozycje;

        let eventsPerDeyDzien = eve.filter((e: any) => (moment(e.start).format("YYYY-MM-DD") === moment(data).format("YYYY-MM-DD")) && e.dzien === '1');
        let eventsPerDeyNoc = eve.filter((e: any) => (moment(e.start).format("YYYY-MM-DD") === moment(data).format("YYYY-MM-DD")) && e.noc === '1');

        return ((eventsPerDeyDzien.length >= 6) && (eventsPerDeyNoc.length >= 6))
    }

    const czyJestNaC = (data: any) => {
        let eve = dyspozycje;
        let czyJest = false;

        let eventsPerDey = eve.filter((e: any) => moment(e.start).format("YYYY-MM-DD") === moment(data).format("YYYY-MM-DD"));

        let c = eventsPerDey.filter((e: any) => e.c === '1');
        if(c.length >= 1){
            czyJest = true;
        }

        return czyJest
    }

    const ileOsob = (data: any) => {
        let eve = dyspozycje;
        // console.log(eve)

        let eventsPerDey = eve.filter((e: any) => moment(e.start).format("YYYY-MM-DD") === moment(data).format("YYYY-MM-DD"));
        // let unique = [...new Set(eventsPerDey.map((item: any) => item.numer))];
        const unique = Array.from(new Set(eventsPerDey.map((item: any) => item.numer)));

        return unique.length
        // return eventsPerDey.length
    }

    const handleSelectSlot = (slot: any) => {
        console.log(slot)

        if(moment(slot.start).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")){
            // setDyspozycja(slot.start);
            setDyspozycja(moment(slot.start).format('YYYY-MM-DD'));
            setCzasStart(moment(slot.start).format('HH:mm'));
            setCzasKoniec((moment(slot.end).format('HH:mm') === '00:00')? '23:59' : moment(slot.end).format('HH:mm'));
            // setDyspozycja(formatDateFromObject(slot.start));
            setShowFormularz(true);
        //     let id = czyJuzZapisanyNaTenDzien(slot)
        //
        //     if(id) {
        //         message.info('Już jesteś dyspozycyjny w tym dniu');
        //
        //         if(moment(slot.start).format("YYYY-MM-DD") >= moment().add(1, 'day').format("YYYY-MM-DD")){
        //             // this.setState({dyspozycja: this.formatDateFromObject(slot.start), dyspozycjaId: id, showModalUsun: true});
        //             setDyspozycja(formatDateFromObject(slot.start));
        //             setDyspozycjaId(id);
        //             setShowModalUsun(true)
        //         }
        //
        //     } else {
        //
        //         if(moment(slot.start).isAfter(moment('2023-09-01')) && moment(slot.start).isBefore(moment('2024-09-01'))){
        //             // dyspozycja ekstra w dniach 2023-06-22 - 2023-06-28
        //             this.setState({dyspozycja: this.formatDateFromObject(slot.start), showFormularzV2: true});
        //             setDyspozycja(formatDateFromObject(slot.start));
        //         } else {
        //             // po staremu
        //             if(this.czySkladNaDzien(slot.start)){
        //                 message.info('Już jest skład podstawowy na ten dzień');
        //             } else {
        //                 if(this.ileOsob(slot.start) === 3){
        //                     if(this.czyJestNaC(slot.start) || czyMamC()){
        //                         this.setState({dyspozycja: this.formatDateFromObject(slot.start), showFormularz: true});
        //                     } else {
        //                         message.warning('Potrzebna osoba z kat C');
        //                     }
        //                 } else {
        //                     this.setState({dyspozycja: this.formatDateFromObject(slot.start), showFormularz: true});
        //                 }
        //             }
        //         }
        //     }
        //
        } else {
            message.warning('Stara data! Wybierz inną datę.');
        }

    }

    const onClickZapisz = () => {
        let czas_start = new Date(dyspozycja + ' ' + czasStart + ':00');
        let czas_end = new Date(dyspozycja + ' ' + czasKoniec + ':00');

        let godz_start = czas_start.getHours()
        let godz_end = czas_end.getHours()

        let godz_do_dodania =0;

        if(czas_start.getTimezoneOffset()!==0){
            godz_do_dodania = -1 * ((czas_start.getTimezoneOffset())/60)
        }
        else{ // if(czas_start.getTimezoneOffset()===0)
            godz_do_dodania = 0;
        }

        czas_start = new Date(czas_start.setHours(godz_start + godz_do_dodania));
        czas_end = new Date(czas_end.setHours(godz_end + godz_do_dodania));

        // let czas_start = moment(moment(dyspozycja).format('YYYY-MM-DD') + ' ' + czasStart);
        // let czas_end = moment(moment(dyspozycja).format('YYYY-MM-DD') + ' ' + czasKoniec);

        dodajDyspozycyjnoscWG(czas_start, czas_end).then((response) => {
            setShowFormularz(false)
            pobierzEventy();
            message.success('Dodano dyspozycyjność');
        }).catch((e) => {
            //console.log('ok')
            setShowFormularz(false)
            message.error(e.response.data);
        })
    }

    const onChangeView = (newView: string) => {
        setView(String(newView));
    }

    const eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
        // console.log(event);
        var backgroundColor = '';
        // var color = '';
        // var border = '';

        if(event.c === '1'){
            backgroundColor = '#5e5e5e'
        }

        if(event.numer === getNumer()){
            backgroundColor = '#d97b02'
        }

        // if(moment().format("YYYY-MM-DD") <= moment(event.start).format("YYYY-MM-DD")){
        //     if(event.c === '1'){
        //         backgroundColor = '#5e5e5e'
        //     }
        //
        //     if(event.id === getNumer()){
        //         backgroundColor = '#d97b02'
        //     }
        // } else {
        //     backgroundColor = 'rgba(171,171,171,0.45)'
        //     color = '#888888'
        //     border = '1px solid #888888'
        // }

        var style = {
            backgroundColor: backgroundColor,
            // color: color,
            // border: border
            // borderRadius: '0px',
            // opacity: 0.8,
            // color: 'black',
            // border: '0px',
            // display: 'block'
        };

        return {style: style};
    }

    const usunDyspozycje = () => {
        let id = dyspozycjaId;

        // delEvent(id).then((response) => {
        //     pobierzEventy();
        //     message.success('Usunięto dyspozycyjność');
        //     // this.setState({showModalUsun: false, dyspozycjaId: null, dyspozycja: null});
        //     setShowModalUsun(false);
        //     setDyspozycjaId(null);
        //     setDyspozycja(null);
        // }).catch((e) => {
        //     //console.log('ok')
        //     // this.setState({showModalUsun: false});
        //     setShowModalUsun(false);
        //     message.error(e.response.data);
        // })
    }

        const localizer = momentLocalizer(moment);

        const translation = {
            date: 'Data',
            time: 'Czas',
            event: 'Wydarzenie',
            allDay: 'Cały dzień',
            week: 'tydzień',
            work_week: 'tydzień roboczy',
            day: 'dzień',
            month: 'miesiąc',
            previous: 'poprzedni',
            next: 'następny',
            yesterday: 'wczoraj',
            tomorrow: 'jutro',
            today: 'dziś',
            agenda: 'lista',
        };

        const EventWrapperComponent = ({ children, value }: any) => {
            // console.log(value)
            return React.cloneElement(Children.only(children), {
                    style: {
                        ...children.style,
                        background: (moment().format("YYYY-MM-DD") <= moment(value).format("YYYY-MM-DD")) ?
                            (moment().format("YYYY-MM-DD") === moment(value).format("YYYY-MM-DD")) ?
                                czySkladNaDzien(value) ? 'linear-gradient(45deg, rgba(144,238,144,1) 36%, rgba(144,238,144,1) 60%, rgba(255,249,7,1) 100%)' : 'linear-gradient(45deg, rgba(255,59,59,1) 40%, rgba(255,59,59,1) 70%, rgba(255,249,7,1) 100%)'
                                :
                                czySkladNaDzien(value) ? 'lightgreen' : '#f5aaaa'
                            :
                            '',
                    },
                }
            )
        };

        return(
            <Content>
                {/*<Box>*/}
                {/*    <h2>Czysty template TOP</h2>*/}
                {/*    <p>Informacje:</p>*/}
                {/*    <p>Dodawanie podstron znajduje się w pliku app_routes.ts. To jest przykładowy Box.</p>*/}
                {/*    <p>Style stałych elementów znajdują się w pliku App.scss.</p>*/}
                {/*    <h3>Zmień rozmiar okna aby zobaczyć efekt.</h3>*/}
                {/*</Box>*/}
                {/*<Box title={'Przykładowy tekst'}>*/}
                {/*    <img src={'https://imgcomfort.com/Userfiles/Upload/images/illustration-geiranger.jpg'}/>*/}
                {/*    /!*style={{maxWidth: '500px'}}*!/*/}
                {/*</Box>*/}

                <div className={'mobilnoscMain'}>
                    {/*{(!czySkladNaDzien(moment().format('YYYY-MM-DD'))) &&*/}
                    {/*    <Alert message="Pełna mobilizacja! Brak podstawowego składu." type="error" className={'alertBrakSkladu'}/>*/}
                    {/*}*/}

                    <Calendar
                        style={{minHeight: '500px'}}
                        className={'dzisTimeline'}
                        culture='pl'
                        messages={translation}
                        selectable={allowToSelect}
                        events={dyspozycje}
                        view={'day'}
                        onView={onChangeView}
                        views={['month', 'day', 'agenda']}
                        // views={['month', 'week', 'day', 'agenda']}
                        step={30}
                        timeslots={4}
                        date={today}
                        onNavigate={(e: any) => changeNavigate(e)}
                        popup

                        min={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0)}
                        // max={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23,59)}

                        // onSelectEvent={(event: any) => handleSelectEvent(event)}
                        // onSelectSlot={(slotInfo: any) => handleSelectSlot(slotInfo)}

                        // components={{dateCellWrapper: EventWrapperComponent}}
                        eventPropGetter={eventStyleGetter}

                        components={{
                            day: {
                                // header: (e: any) => <div onClick={() => console.log(e)}>aaa</div>,
                                // event: (e: any) => <div onClick={() => console.log(e)}>dddd</div>
                            },
                        }}
                        localizer={localizer}/>
                    {/*<h3 style={{textAlign: 'center'}}>Kalendarz mobilności</h3>*/}

                <Calendar
                    style={{minHeight: '500px'}}
                    className={'kalendarzMobilnosci'}
                    culture='pl'
                    messages={translation}
                    selectable={allowToSelect}
                    events={dyspozycje}
                    view={view}
                    onView={onChangeView}
                    views={['month', 'day', 'agenda']}
                    // views={['month', 'week', 'day', 'agenda']}
                    step={stepValue}
                    timeslots={timeslotsValue}
                    date={scrollDate}
                    onNavigate={(e: any) => changeNavigate(e)}
                    popup

                    min={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0)}
                    // max={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23,59)}

                    onSelectEvent={(event: any) => handleSelectEvent(event)}
                    onSelectSlot={(slotInfo: any) => handleSelectSlot(slotInfo)}

                    // components={{dateCellWrapper: EventWrapperComponent}}
                    eventPropGetter={eventStyleGetter}

                    components={{
                    //     event: this.formatEvent,
                    //     week: {
                    //         event: this.formatUrlEvent
                    //     },
                        month: {
                            // header: (e: any) => <div>d</div>
                            dateHeader: (e: any) =>
                                <div className={'dzienHeader'}>
                                    {/*{(moment(e.date).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD"))&& <button style={{textAlign: 'left'}}>Dodaj</button>}*/}
                                    {(moment(e.date).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")) ?
                                        <div className={'dzienIleOsob'} style={{color: (ileOsob(e.date) === 0)? 'red' : 'unset'}}><UserOutlined/> {ileOsob(e.date)}</div>
                                        :
                                        <div></div>
                                    }
                                    {/*{(moment(e.date).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD"))&&*/}
                                    {/*    <div className={'onDesktop btnDodajMob'}>dodaj</div>*/}
                                    {/*}*/}
                                    {e.label}
                                </div>
                            // event: () => null
                        },
                        day: {
                            // header: (e: any) => <div onClick={() => console.log(e)}>aaa</div>,
                            // event: (e: any) => <div onClick={() => console.log(e)}>dddd</div>
                        },
                        // agenda: {
                        //     event: (e: any) => e.title
                        // }
                    }}
                    localizer={localizer}/>

                    <div className={'legenda'}>
                        <div className={'kolor'}>
                            <div className={'kd kolorJ'}/>
                            <label>Ja</label>
                        </div>
                        <div className={'kolor'}>
                            <div className={'kd kolorR'}/>
                            <label>Ratownik</label>
                        </div>
                        <div className={'kolor'}>
                            <div className={'kd kolorC'}/>
                            <label>Kierowca C</label>
                        </div>
                    </div>

                    {/*{*/}
                    {/*    showFormularz &&*/}
                    {/*    <PopupWrapper header={'Dodaj dyspozycyjność'}*/}
                    {/*                  shouldNotCloseWithoutClick={true} withoutOverflowY={false}*/}
                    {/*                  onClose={() => this.setState({showFormularz: false})}*/}
                    {/*    >*/}
                    {/*        <DodajDyspozycyjnosc handleClickAnuluj={() => this.setState({showFormularz: false})} handleClickWyslij={this.onClickZapisz} dyspozycja={dyspozycja}/>*/}
                    {/*    </PopupWrapper>*/}
                    {/*}*/}

                    {/*{*/}
                    {/*    showFormularzV2 &&*/}
                    {/*    <PopupWrapper header={'Dodaj dyspozycyjność'}*/}
                    {/*                  shouldNotCloseWithoutClick={true} withoutOverflowY={false}*/}
                    {/*                  onClose={() => this.setState({showFormularzV2: false})}*/}
                    {/*    >*/}
                    {/*        <DodajDyspozycyjnoscV2 handleClickAnuluj={() => this.setState({showFormularzV2: false})} handleClickWyslij={this.onClickZapiszV2} dyspozycja={dyspozycja}/>*/}
                    {/*    </PopupWrapper>*/}
                    {/*}*/}

                    {/*<Modal*/}
                    {/*    // className="QRModal"*/}
                    {/*    title="Usuń dyspozycje"*/}
                    {/*    visible={showModalUsun}*/}
                    {/*    onOk={this.usunDyspozycje}*/}
                    {/*    okText="Usuń"*/}
                    {/*    cancelText={'Anuluj'}*/}
                    {/*    onCancel={() => this.setState({showModalUsun: false, dyspozycjaId: null, dyspozycja: null})}*/}
                    {/*>*/}
                    {/*    Jesteś dyspozycyjny w tym dniu.<br/>*/}
                    {/*    Czy chcesz usunąć dyspozycje z dnia {dyspozycja}?*/}
                    {/*</Modal>*/}

                    <Modal
                        // className="QRModal"
                        title="Dodaj dyspozycje"
                        visible={showFormularz}
                        onOk={() => onClickZapisz()}
                        okText="Dodaj"
                        cancelText={'Anuluj'}
                        onCancel={() => {
                            setShowFormularz(false);
                            setDyspozycjaId(null);
                            setDyspozycja(null);
                        }}
                    >
                        <Row>
                            <Col span={24}><h5 style={{textAlign: 'center', padding: '15px'}}>{dyspozycja}</h5></Col>
                        </Row>
                        <Row className={'mb-5'}>
                            <Col span={12} style={{textAlign: 'center'}}>
                                <label>Start</label>
                                <FormControl name={'time-picker-start'}
                                             type={'time'} onChange={(e: any) => setCzasStart(e.target.value)}
                                             value={czasStart}
                                             // defaultValue={'06:00'}
                                             style={{width: 'unset', margin: 'auto'}}
                                    // disabled={this.state.dataEventStart != this.state.dataEventEnd}
                                />
                            </Col>
                            <Col span={12} style={{textAlign: 'center'}}>
                                <label>Koniec</label>
                                <FormControl name={'time-picker-koniec'}
                                             type={'time'} onChange={(e: any) => setCzasKoniec(e.target.value)}
                                             value={czasKoniec}
                                             // defaultValue={'18:00'}
                                             style={{width: 'unset', margin: 'auto'}}
                                    // disabled={this.state.dataEventStart != this.state.dataEventEnd}
                                />
                            </Col>
                        </Row>
                    </Modal>

                </div>
            </Content>
        )
}

export default MobilnoscMain;
