import * as React from 'react';
import {Link} from "react-router-dom";
import {getImieNazwisko, wyloguj} from "../utilities/Serwis";
import {BarsOutlined, PoweroffOutlined} from "@ant-design/icons";
import {Button, Col, Drawer, Row, Space} from "antd";
import {useState} from "react";
import {DrawerProps} from "antd/es/drawer";
import SideNav from "./SideNav";
import MenuOnMobile from "./MenuOnMobile";
// import {Col, Row} from "react-bootstrap";

const Header = () => {

    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState<DrawerProps['placement']>('left');

    const onClose = () => {
        setOpen(false);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const renderLokalizacja = () => {
        let path = window.location.pathname;
        let lokalizacja = '';

        if(path.indexOf('dyzury') !== -1){
            lokalizacja = 'Dyżury';
        } else if (path.indexOf('mobilnosc') !== -1){
            lokalizacja = 'Kalendarz mobilności';
        }

        return lokalizacja
    }

    return(
    <div className = {'header'} >
        {/*<button id={'menuButton'} onClick={showDrawer}>*/}
        {/*    <span><BarsOutlined /></span>*/}
        {/*</button>*/}


            {/*<span onClick={this.onMenu} className="navbar-toggler-icon"><i className={'fa fa-bars'}/></span>*/}


        <Row style={{color: 'white', width: '100%', textAlign: 'center'}}>
            {/*<Col span={6} offset={6}><b>{renderLokalizacja()}</b></Col>*/}
            {/*<Col span={3} offset={9}><b>{renderLokalizacja()}</b></Col>*/}
            <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                <button id={'menuButton'} onClick={showDrawer}>
                    <span><BarsOutlined /></span>
                </button>
            </Col>
            <Col xs={20} sm={16} md={12} lg={8} xl={4}>
                <b>{renderLokalizacja()}</b>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={10} style={{textAlign: 'end', paddingRight: '15px', display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                <button type="button" className="onDesktop btn btn-danger btnWlg" onClick={() => wyloguj()}><PoweroffOutlined /> Wyloguj</button>
            </Col>
            {/*<Col>{getImieNazwisko()}</Col>*/}
        </Row>

        {/*<div className={'push'}>*/}
        {/*    <button type="button" className="onDesktop btn btn-danger" onClick={() => wyloguj()}><PoweroffOutlined /> Wyloguj</button>*/}
        {/*    /!*<button type="button" className="onMobile btn btn-danger" onClick={() => wyloguj()}><PoweroffOutlined /></button>*!/*/}
        {/*</div>*/}

        <Drawer
            title="OSP Rzeszów - Słocina"
            placement={placement}
            // width={500}
            onClose={onClose}
            open={open}
            // extra={
            //     <Space>
            //         <Button onClick={onClose}>Cancel</Button>
            //         <Button type="primary" onClick={onClose}>
            //             OK
            //         </Button>
            //     </Space>
            // }
        >
            <MenuOnMobile closeMenu={() => setOpen(false)}/>
        </Drawer>

    </div>
    )
}

export default Header;
