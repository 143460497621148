import * as React from 'react'
import app_routes from "../configuration/app_routes";
// @ts-ignore
import {Link} from "react-router-dom";
// import {Col, Row} from "react-bootstrap";
import {getImieNazwisko, isAdmin} from "../utilities/Serwis";
import { UserOutlined } from '@ant-design/icons';
import {Avatar} from "antd";

const MenuOnDesktop = () => {

    return(
        <div>
            <div className={'sidebar'}>
                <div id={'sidebar-header'}>
                    <Avatar size="small" icon={<UserOutlined/>} style={{marginRight: '15px'}}/>
                    <span>{getImieNazwisko()}</span>
                </div>
                {
                    app_routes.APP_ROUTES().map((item: any) => (
                        (item.admin)?
                            (isAdmin())&&
                                <div key={item.url}>
                                    <div className={'menuItems'}>
                                        <Link to={item.url}>
                                            <div className={(window.location.pathname === item.url) ? 'current' : ""}
                                                 onClick={() => {
                                                     console.log("go to " + item.name)
                                                 }}>
                                                {/*<i className={item.icon}></i> {<span>{item.name}</span>}*/}
                                                {item.iconAnt} {<label>{item.name}</label>}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                        :
                        <div key={item.url}>
                            <div className={'menuItems'}>
                                <Link to={item.url}>
                                    <div className={(window.location.pathname === item.url) ? 'current' : ""}
                                         onClick={() => {
                                             console.log("go to " + item.name)
                                         }}>
                                        {/*<i className={item.icon}></i> {<span>{item.name}</span>}*/}
                                        {item.iconAnt} {<label>{item.name}</label>}
                                    </div>
                                </Link>
                            </div>
                        </div>

                    ))
                }
            </div>
        </div>
    )
}

export default MenuOnDesktop;
