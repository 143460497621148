

    export const APP_NAME = "OSP Rzeszów - Słocina v2"

    export const APP_VER = "v0.07"

    export const APP_UPDATE = "2024-09-12"

    export const APP_UPDATE_SHORT = APP_UPDATE.substr(0,10)

    export const HOST_API = `https://dyzury.osprzeszowslocina.pl/`

    // if((window.location.origin.indexOf('localhost') > -1) || (window.location.origin.indexOf('10.10.10.92') > -1)){
    //     return 'https://osp.netserwer.pl/'
    // } else {
    //     return `https://dyzury.osprzeszowslocina.pl/`
    // }
