import {Link} from "react-router-dom";
import logo from "./../logo.svg"
import {logowanie} from "../../utilities/Serwis";
import {Button, Input, message} from "antd";
import {APP_VER} from "../../configuration/AppConfiguration";
import {useState} from "react";

const LoginMain = () => {

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const zaloguj = (pass: any, tel: any) => {
        logowanie(pass, tel).then(res => {
            if (res.status === 200){
                console.log(res)
                let data = res.data
                data.password = pass
                localStorage.setItem('sesjaUzytkownikaSystemuOSPv2', JSON.stringify({imie: res.data[0].IMIE, nazwisko: res.data[0].NAZWISKO, numer: tel, dostep: res.data[0].DOSTEP, c: res.data[0].PRAW_C}))
                window.location.reload()
                //this.props.history.push('/')
            } else {
                // console.log('error')
                message.error('Error');
            }
        }).catch(error => {
            // console.log('Zle dane logowania');
            message.error('Złe dane logowania');
            // toast.error("Błąd logowania!");
        });
    }

    const onClickZaloguj = () => {
        zaloguj(password, login);
    }

    const onChangeUsername = (e: any) => {
        setLogin(e.target.value);
    }

    const onChangePassword = (e: any) => {
        setPassword(e.target.value);
    }


    return(
        <div className={'loginView'}>
            <div className={'loginComponent'}>
                {/*<img src={logo} alt={'logo'} className={'logo'} style={{width: '320px'}}/>*/}
                <h1 style={{marginBottom: '0'}}>OSP Dyżury</h1>
                <h6 style={{marginTop: '0', textAlign: 'center'}}>OSP Rzeszów - Słocina</h6>
                {/*<div style={{textAlign: 'center'}}>*/}
                {/*    <span style={{textAlign: 'center'}}>*/}
                {/*{APP_VER}*/}
                {/*</span>*/}
                {/*</div>*/}
                <div className={'loginContainer'}>
                    <form>
                        {/*<label>Numer telefonu:</label>*/}
                        <div>
                            <Input addonBefore="Nr telefonu" defaultValue="" onChange={onChangeUsername}/>
                        {/*    <input required type={'text'} className={"form-control"} value={login}*/}
                        {/*            onChange={onChangeUsername}*/}
                        {/*/>*/}
                        </div>
                        {/*<label>Hasło:</label>*/}
                        <div>
                            <Input.Password addonBefore="Hasło" defaultValue="" onChange={onChangePassword}/>
                        {/*    <input required type={'password'} className={'form-control'} value={password}*/}
                        {/*            onChange={onChangePassword}*/}
                        {/*/>*/}
                        </div>
                        <div>
                            <Button type="default" block onClick={() => onClickZaloguj()}>
                                ZALOGUJ
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LoginMain;
