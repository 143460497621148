import {
    CalendarOutlined,
    InfoCircleOutlined,
    SettingOutlined,
    UserOutlined,
    ContactsOutlined
} from '@ant-design/icons';
export default class app_routes{

    static APP_ROUTES() {return [
        {
            icon: "fa fa-calendar",
            iconAnt: <CalendarOutlined />,
            name: "Dyżury",
            url:"/dyzury",
            admin: false,
        },
        {
            icon: "fa fa-calendar",
            iconAnt: <ContactsOutlined />,
            name: "Mobilność",
            url:"/mobilnosc",
            admin: false,
        },
        {
            icon: "fa fa-users",
            iconAnt: <UserOutlined />,
            name: "Użytkownicy",
            url:"/uzytkownicy",
            admin: true,
        },
        {
            icon: "fa fa-cog",
            iconAnt: <SettingOutlined />,
            name: "Ustawienia",
            url:"/ustawienia",
            admin: false,
        },
        {
            icon: "fa fa-info",
            iconAnt: <InfoCircleOutlined />,
            name: "Informacje",
            url:"/info",
            admin: false,
        },

    ]}
}
