import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import {isAdmin, isLogged} from "./utilities/Serwis";
import Page404 from "./modules/Page404";
import './App.css';
import LoginMain from "./modules/Login/LoginMain";
import MobilnoscMain from "./modules/Mobilnosc/MobilnoscMain";
import DyzuryMain from "./modules/Dyzury/DyzuryMain";
import UzytkownicyMain from './modules/Uzytkownicy/UzytkownicyMain';
import UstawieniaMain from './modules/Ustawienia/UstawieniaMain';
import InfoMain from './modules/Info/InfoMain';

function App() {
  return (
      <Router>
        <Routes>
          {/*<Route path={"/404"} Component={isLogged()? Page404 : LoginMain}/>*/}
          <Route path={"/login"} Component={isLogged()? () => <Navigate to={"/start"}/> : LoginMain}/>
          <Route path={"/"} Component={isLogged()? () => <Navigate to={"/dyzury"}/> : LoginMain}/>
          <Route path={"/dyzury"} Component={isLogged()? DyzuryMain : LoginMain}/>
          <Route path={"/mobilnosc"} Component={isLogged()? MobilnoscMain : LoginMain}/>
          <Route path={"/uzytkownicy"} Component={(isLogged() && isAdmin())? UzytkownicyMain : LoginMain}/>
          <Route path={"/ustawienia"} Component={isLogged()? UstawieniaMain : LoginMain}/>
          <Route path={"/info"} Component={isLogged()? InfoMain : LoginMain}/>
          {/*<Route path={'*'} Component={isLogged()? Page404 : LoginMain}/>*/}
          <Route path={'*'} Component={() => <Navigate to={'/'}/>}/>
        </Routes>
      </Router>
  );
}

export default App;
