import * as React from 'react'
import app_routes from "../configuration/app_routes";
// @ts-ignore
import {Link} from "react-router-dom";
import {getImieNazwisko, isAdmin, wyloguj} from "../utilities/Serwis";
import {UserOutlined, PoweroffOutlined} from "@ant-design/icons";
import {Avatar} from "antd";

interface Props {
    closeMenu: () => void
}

const MenuOnMobile = ({closeMenu}: Props) => {

    return(
        <div className="menuOnMobileMain">
            <div id={'sidebar-header'}>
                <Avatar size="small" icon={<UserOutlined />} style={{marginRight: '15px'}}/>
                <span style={{display: 'inline-block'}}>{getImieNazwisko()}</span>
                {/*<span>MENU</span>*/}
            </div>

            <div className={'menuOnMobile'}>
                {
                    app_routes.APP_ROUTES().map((item: any) => (
                        (item.admin)?
                            (isAdmin())&&
                            <div key={item.url}>
                                <div className={'menuItems'}>
                                    <Link to={item.url}>
                                        <div className={(window.location.pathname === item.url) ? 'current' : ""}
                                             onClick={() => {
                                                 console.log("go to " + item.name)
                                                 closeMenu();
                                             }}>
                                            {/*<i className={item.icon}></i> {<span>{item.name}</span>}*/}
                                            {item.iconAnt} {<label>{item.name}</label>}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            :
                            <div key={item.url}>
                                <div className={'menuItems'}>
                                    <Link to={item.url}>
                                        <div className={(window.location.pathname === item.url) ? 'current' : ""}
                                             onClick={() => {
                                                 console.log("go to " + item.name)
                                                 closeMenu();
                                             }}>
                                            {/*<i className={item.icon}></i> {<span>{item.name}</span>}*/}
                                            {item.iconAnt} {<label>{item.name}</label>}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                    ))
                }
                <hr style={{margin: '15px 15px', color: 'white'}}/>
                <div className={'wylogujMobile'}>
                    <button type="button" className="btn btn-outline-danger" onClick={() => wyloguj()}><PoweroffOutlined /> Wyloguj</button>
                </div>
            </div>
        </div>

    )
}

export default MenuOnMobile;
